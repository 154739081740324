<template>
  <div class="error">
    <div class="error-code">404</div>
    <div class="error-message">抱歉，没有你要找的内容...</div>
    <van-button plain round class="bottom-button custom-button" @click="back('sm')">首页</van-button>
    <van-button plain round class="bottom-button custom-button" @click="back('back')">返回</van-button>
  </div>
</template>


<script>
import router from "@/router"

export default {
  name: "errorPage",
  methods: {
    back(tag) {
      if (tag === 'sm') {
        router.push("/qrLogin/")
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>


<style scoped>
.error {
  display: flex;
  flex-direction: column; /* 元素垂直排列 */
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #F8F8F8;
}

.error-code {
  color: #101010; /* 文字颜色 */
  font-size: 6rem; /* 大号字体 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.error-message {
  font-size: 14px; /* 字体大小 */
  color: #636365; /* 文字颜色 */
}


.bottom-button {
  width: 200px;
  height: 30px;
  margin: 7px;
  transition: box-shadow 0.2s ease, transform 0.2s ease; /* 平滑过渡效果 */
  border: none;
}

.custom-button {
  background-color: #f1404b; /* 初始背景颜色 */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: white; /* 文字颜色 */
}

.custom-button:hover,
.custom-button:active {
  background-color: #363636; /* 悬停和触摸时背景变黑 */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); /* 点击时的阴影，看起来更靠近页面 */
  transform: translateY(2px); /* 稍微向下移动按钮，增强下沉效果 */
}
</style>